<template>
  <div class="pa-3">
    <div class="text-center mx-auto mb-5" v-if="false">
      <v-row>
        <v-col class="grow" cols="1">
          <v-icon @click="dialog = true" large color="green">mdi-music-note</v-icon>
        </v-col>
        <v-col class="grow" cols="1">
          <v-icon @click="ira" large color="blue">mdi-chart-bar</v-icon>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="grow">
          <v-icon @click="dialogElimina = !dialogElimina" large color="yellow">mdi-flash</v-icon>
        </v-col>
      </v-row>
    </div>

    <div class="text-center mx-auto mt-12" v-if="!desserts.length">
      <v-avatar height="200" width="200">
        <v-img class="text-center mx-auto" height="200" width="200" src="cocina.png"></v-img>
      </v-avatar>
      <h2 class="mt-3">¡TUS PEDIDOS LLEGARÁN AQUÍ!</h2>
    </div>

    <v-row class="mx-auto mt-2" dense>
      <v-col v-for="item in formattedDesserts" :key="item.id" cols="6" md="3" sm="4" xs="4">
        <v-card elevation="3" :color="asignaColor_deliv(item.delivery)" class="mb-3">
          <v-progress-linear :value="item.progress" :color="item.color" height="5" stream
            class="mb-1"></v-progress-linear>
          <v-row no-gutters class="mb-1">
            <v-col cols="6" class="text-center">
              <v-btn x-small @click="completaPedido(item)" color="white">
                <v-icon color="green">mdi-check</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn x-small @click="cancelaPedido(item)" color="white">
                <v-icon color="red">mdi-window-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-text class="red--text" style="font-size:1.95vh">
            <v-row dense>
              <v-col cols="7">
                <span class="black--text" v-if="item.delivery">Deliv. : <strong>{{ item.idmesa }}</strong> </span>
                <span class="black--text" v-else>MESA : <strong>{{ item.idmesa }}</strong> </span>
                <p class="black--text mb-n1" v-if="!item.delivery">Resp. : <strong>{{ item.usuario }}</strong> </p>
                <p class="mb-n1" v-if="item.obsmesa != '-'">{{ item.obsmesa }}</p>
              </v-col>
              <v-col cols="5">
                <span>{{ item.formattedTime }} </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="red--text mt-n6" style="font-size:2vh;text-align:left;">
            <div v-for="items in item.data" :key="items.uuid" cols="12" class="mt-2">
              <v-row no-gutters>
                <v-col cols="2">
                  <p class="black--text">{{ items.cantidad }}</p>
                </v-col>
                <v-col cols="10">
                  <p class="black--text">{{ items.nombre }}</p>
                </v-col>
              </v-row>
              <p class="mt-n2" style="font-size:2vh;text-align:center;" v-if="items.observacion != '-'">
                {{ items.observacion }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="390">
      <div>
        <v-system-bar window dark>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-system-bar>
      </div>
      <v-card class="pa-3">
        <v-select v-model="musica" :items="arrayMusica" menu-props="auto" hide-details label="Melodia"></v-select>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="naranja" label="naranja"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="rojo" label="rojo"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-btn :color="$store.state.colorselecto" @click="$store.commit('dialogoaPaletaColores')">
              Color Deliv.
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-checkbox dense v-model="progreso" label="Progreso"></v-checkbox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="GrabaMelodia">Graba</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogElimina" max-width="390">
      <div>
        <v-system-bar window dark>
          <v-icon @click="dialogElimina = false">mdi-close</v-icon>
        </v-system-bar>
      </div>
      <v-card class="pa-3">
        <v-img class="mx-auto" height="80" width="80" src="/iconalerta.png"></v-img>
        <v-card-text>
          Este procedimiento mejora la rapidez en que llegan tus pedidos. Se recomienda hacerlo antes de iniciar tu
          jornada o al finalizar. ¿Deseas hacerlo ahora?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="elimina">Sí</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <flotante @evento="evento" />
    <paletaColores />
  </div>
</template>

<script>
import { allCocina, completaCocina, grabaConfigura, grabareportecocina, eliminaTotalCocina, eliminaCocina } from '../../db'
import store from '@/store/index'
import moment from 'moment'
import flotante from '@/components/botonflotante'
import paletaColores from '@/components/dialogos/paletaColores'

export default {
  components: {
    flotante,
    paletaColores
  },

  data() {
    return {
      barracolor: false,
      desserts: [],
      dialog: false,
      dialogElimina: false,
      arrayMusica: ["Campana", "Corneta"],
      musica: '',
      tiempo: 0,
      progres: 0,
      naranja: '600',
      rojo: '1200',
      progreso: false
    }
  },

  mounted() {
    allCocina().orderByChild('fecha').on("value", this.onDataChange);
    this.musica = store.state.configuracion.melodia
    this.naranja = store.state.configuracion.naranja
    this.rojo = store.state.configuracion.rojo
    this.progreso = store.state.configuracion.progreso
    store.commit('colorselecto', store.state.configuracion.color_deliv)
    setInterval(this.actualizaTiempo, 1000);
  },

  beforeDestroy() {
    allCocina().off("value", this.onDataChange);
  },

  computed: {
    formattedDesserts() {
      return this.desserts.map(item => {
        const tempo = this.tiempo - item.fecha
        return {
          ...item,
          progress: tempo / 15,
          color: this.getColor(item.fecha),
          formattedTime: this.getTimeDifference(item.fecha)
        }
      })
    }
  },

  methods: {
    asignaColor_deliv(date) {
      return date ? store.state.colorselecto : ''
    },

    actualizaTiempo() {
      this.tiempo = moment().unix()
    },

    getTimeDifference(fecha) {
      const tempo = this.tiempo - fecha
      return this.secondsToString(tempo)
    },

    getProgress(fecha) {
      return (this.tiempo - fecha) / 15
    },

    getColor(fecha) {
      const tempo = this.tiempo - fecha
      if (tempo > store.state.configuracion.rojo) return "red"
      if (tempo > store.state.configuracion.naranja) return "orange"
      return "green"
    },

    secondsToString(seconds) {
      const minute = Math.floor((seconds / 60) % 60).toString().padStart(2, '0')
      const second = (seconds % 60).toString().padStart(2, '0')
      return `${minute}:${second}`
    },

    onDataChange(items) {
      const array = []
      items.forEach(item => {
        const data = { ...item.val(), uuid: item.key }
        array.push(data)
      })
      this.filtramusica(store.state.configuracion.melodia)
      this.desserts = array
    },

    GrabaMelodia() {
      this.filtramusica(this.musica)
      grabaConfigura("melodia", this.musica)
      grabaConfigura("naranja", this.naranja)
      grabaConfigura("rojo", this.rojo)
      grabaConfigura("progreso", this.progreso)
      grabaConfigura("color_deliv", store.state.colorselecto)
      this.dialog = false
    },

    completaPedido(item) {
      item.estado = "aprobado"
      item.fecha_atencion = moment().unix()
      grabareportecocina(item).then(() => eliminaCocina(item.uuid))
    },

    cancelaPedido(item) {
      item.estado = "cancelado"
      item.fecha_atencion = moment().unix()
      grabareportecocina(item).then(() => eliminaCocina(item.uuid))
    },

    filtramusica(value) {
      const melodias = value === 'Campana' ? '/campana.mp3' : '/trompeta.mp3'
      this.playSound(melodias)
    },

    evento(item) {
      if (item === 1) this.dialog = true
      if (item === 2) this.ira()
      if (item === 3) this.dialogElimina = true
    },

    ira() {
      this.$router.push({ path: '/reportecocinav3/cocina' })
    },

    elimina() {
      eliminaTotalCocina()
      this.dialogElimina = false
    },

    playSound(sound) {
      if (sound) {
        const audio = new Audio(sound)
        audio.play()
      }
    },
  }
}
</script>

<style scoped>
.pa-3 {
  padding: 16px;
}

.text-center {
  text-align: center;
}

.mx-auto {
  margin: 0 auto;
}

.mb-5 {
  margin-bottom: 32px;
}

.mt-12 {
  margin-top: 48px;
}

.mt-2 {
  margin-top: 16px;
}

.mb-3 {
  margin-bottom: 16px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mt-1 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 24px;
}

.red--text {
  color: red !important;
}

.black--text {
  color: black !important;
}
</style>
